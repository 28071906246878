import { Event } from '@/modules/Event';
import { Lead } from '@/modules/Lead';
export class Submit extends Event {
    constructor() {
        super('submit');
        this.#getLeadFormInfo();
    }
    #getLeadFormInfo() {
        const forms = window.document.querySelectorAll('form');
        forms.forEach((form) => {
            form.addEventListener('submit', async (e) => {
                const newLead = { email: '', name: '', mobilephone: '' };
                const elements = e.currentTarget?.elements;
                if (elements) {
                    Array.from(elements).forEach(({ name, value }) => {
                        const matches = name.match(FIELD_NAME_REGEX);
                        if (matches) {
                            const key = matches[0].toLowerCase();
                            newLead[key] = value;
                        }
                    });
                }
                const lead = new Lead({
                    email: newLead.email,
                    name: newLead.name,
                    phone: newLead.mobilephone,
                });
                if (lead.leadDataIfValid) {
                    this.data.lead = lead.leadDataIfValid;
                }
            });
        });
    }
}
const FIELD_NAME_REGEX = /(?=\w*)(Name)|(Email)|(MobilePhone)(?=\w*)/gi;
