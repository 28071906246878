import { mountDivClass } from '../../utils/mountDivClass';
import { mountLabel } from '../../utils/mountLabel';
import { mountTextualInput } from '../../utils/mountTextualInput';
import { mountDatalistOptions } from './options';
export const renderDatalistInputs = (props, styles) => {
    const div = document.createElement('div');
    div.className = mountDivClass(props, styles);
    const label = mountLabel(props, styles);
    const inputElement = mountTextualInput(props, styles);
    const datalist = document.createElement('datalist');
    datalist.id = 'form-list-' + props.name;
    mountDatalistOptions(props.options, [datalist]);
    div.appendChild(label);
    div.appendChild(inputElement);
    div.appendChild(datalist);
    return div;
};
