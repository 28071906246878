export const mountSpanError = (divInput, styles) => {
    const hasSpan = divInput.querySelector('.message-danger');
    if (!divInput.contains(hasSpan)) {
        const spanElement = document.createElement('span');
        const text = document.createTextNode('Campo preenchido de forma inválida!');
        spanElement.className = styles?.error ? styles.error : 'message-danger';
        spanElement.role = 'alert';
        spanElement.style.fontWeight = 'bold';
        spanElement.appendChild(text);
        divInput.appendChild(spanElement);
        return spanElement;
    }
};
