import { mountDivClass } from '../../utils/mountDivClass';
import { mountLabel } from '../../utils/mountLabel';
import { mountSelectOptions } from './options';
export const renderSelectInputs = (props, styles) => {
    const div = document.createElement('div');
    const label = mountLabel(props, styles);
    const select = mountSelect(props, styles);
    const wrapper = document.createElement('div');
    if (styles?.wrapper) {
        wrapper.className += styles.wrapper.concat(' ');
    }
    div.className = mountDivClass(props, styles);
    div.appendChild(label);
    div.appendChild(wrapper);
    wrapper.appendChild(select);
    return div;
};
const mountSelect = ({ name, ...props }, styles) => {
    const select = document.createElement('select');
    select.innerHTML = '';
    select.name = `form_fields[${name}]`;
    select.id = `form-field-${name}`;
    if (styles?.select) {
        select.className = styles.select;
    }
    if (props.isRequired) {
        select.required = props.isRequired;
        select.ariaRequired = String(props.isRequired);
    }
    mountSelectOptions(props.options, select);
    return select;
};
