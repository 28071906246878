export const mountTextualInput = ({ name, type, value, ...props }, styles) => {
    const inputElement = document.createElement('input');
    inputElement.type = type === 'datalist' ? 'text' : type;
    inputElement.name = `form_fields[${name}]`;
    inputElement.id = `form-field-${name}`;
    if (value) {
        inputElement.value = value;
    }
    if (styles?.input) {
        inputElement.className = styles.input.concat(' ');
    }
    if (styles?.required) {
        inputElement.className += styles.required;
    }
    if (props.placeholder) {
        inputElement.placeholder = props.placeholder;
    }
    if (props.isRequired) {
        inputElement.required = props.isRequired;
        inputElement.ariaRequired = String(props.isRequired);
    }
    inputElement.setAttribute('list', `form-list-${name}`);
    return inputElement;
};
