import { mountDivClass } from '../../utils/mountDivClass';
import { mountLabel } from '../../utils/mountLabel';
import { mountOption } from './options';
export const renderRadioInputs = (props, styles) => {
    const label = mountLabel(props, styles);
    const radioGroup = mountRadioGroup(props, styles);
    const div = document.createElement('div');
    div.className = mountDivClass(props, styles);
    div.appendChild(label);
    div.appendChild(radioGroup);
    return div;
};
const mountRadioGroup = (props, styles) => {
    const radioGroup = document.createElement('div');
    if (styles?.radio)
        radioGroup.className = styles.radio;
    props.options.forEach((option, index) => {
        const optionSpan = mountOption(props, option, index);
        radioGroup.appendChild(optionSpan);
    });
    return radioGroup;
};
