export const mountDivClass = (props, styles) => {
    //const type = inputTypes[props.type as InputTypes] || props.type;
    let className = '';
    if (styles?.div) {
        className += styles.div.concat(' ');
    }
    if (props.isRequired && styles?.required) {
        className += styles.required.concat(' ');
    }
    if (styles?.hidden) {
        className += styles.hidden;
    }
    return className;
};
const inputTypes = {
    datalist: 'text',
};
//type InputTypes = keyof typeof inputTypes;
