export const selectOptionsProfessions = [
    'Administrador de Banco de Dados',
    'Açougueiro',
    'Acompanhante de Idosos',
    'Acupunturista',
    'Administrador de Empresas',
    'Administrador de Redes',
    'Administrador de Redes Junior',
    'Administrador de Redes Pleno',
    'Administrador de Redes Sênior',
    'Administrador de Sistemas',
    'Administrador Hospitalar',
    'Administrador Público',
    'Advogado',
    'Advogado Civil',
    'Aeronauta',
    'Afiador de Ferramentas',
    'Agente Comunitário de Saúde',
    'Agente de Defesa Sanitária',
    'Agente de Viagens',
    'Agente Funerário',
    'Agente Penitenciário',
    'Agricultor',
    'Agrônomo',
    'Ajudante de Motorista',
    'Ajudante de Transportes',
    'Alfaiate',
    'Almirante',
    'Almoxarife',
    'Ambientalista',
    'Ambulante',
    'Analista Administrativo',
    'Analista Administrativo de Vendas',
    'Analista Administrativo Financeiro',
    'Analista Ambiental',
    'Analista Comercial Interno',
    'Analista Comercial Externo',
    'Analista de Atendimento a Clientes',
    'Analista de Atendimento Comercial',
    'Analista de Banco',
    'Analista de Banco de Dados',
    'Analista de Cargos e Salários',
    'Analista de Cobrança',
    'Analista de Comércio Exterior',
    'Analista de Compras',
    'Analista de Comunicação',
    'Analista de Contabilidade',
    'Analista de Contas a Pagar e Receber',
    'Analista de Controladoria',
    'Analista de Controladoria Junior',
    'Analista de Controladoria Pleno',
    'Analista de Controladoria Sênior',
    'Analista de Controle de Qualidade',
    'Analista de Crédito e Cobrança',
    'Analista de Custos',
    'Analista de Departamento Pessoal',
    'Analista de Desenvolvimento de Produto',
    'Analista de Desenvolvimento de Sistemas',
    'Analista de Desenvolvimento Web',
    'Analista de Distribuição',
    'Analista de Ecommerce',
    'Analista de Estoque',
    'Analista de Engenharia Elétrica',
    'Analista Financeiro',
    'Analista de Fiscal',
    'Analista Químico',
    'Anestesiologista',
    'Angiologista',
    'Antropólogo',
    'Apicultor',
    'Apresentador',
    'Argumentista',
    'Armador',
    'Armeiro',
    'Arquiteto',
    'Arquiteto de Interiores',
    'Arquiteto Paisagista',
    'Arquiteto Urbanista',
    'Arquiteto Projetista',
    'Arquiteto Vendedor',
    'Arquivista',
    'Arrumadeira',
    'Artista de Espetáculo de Diversões',
    'Artista Plástico',
    'Ascensorista',
    'Assessor de Imprensa',
    'Analista de Fundição',
    'Analista de Informática',
    'Analista de Investimentos',
    'Analista de Logística',
    'Analista de Loja',
    'Analista de Manutenção',
    'Analista de Manutenção Elétrica',
    'Analista de Marketing',
    'Analista de PCP',
    'Analista de Produção',
    'Analista de Programação',
    'Analista de Publicidade e Propaganda',
    'Analista de Qualidade',
    'Analista de Recrutamento e Seleção',
    'Analista de Recursos Humanos',
    'Analista de Redes',
    'Analista de Relações Públicas',
    'Analista de Representação Comercial',
    'Analista de SAC',
    'Analista de Segurança Patrimonial',
    'Analista de Seguros',
    'Analista de Sistemas',
    'Analista de Suporte Técnico',
    'Analista de Suprimentos',
    'Analista de Tecnologia da Informação (TI)',
    'Analista de Telecomunicações',
    'Analista de Telemarketing Ativo',
    'Analista de Telemarketing Receptivo',
    'Analista de Tesouraria',
    'Analista de Transportes',
    'Analista de Treinamento',
    'Analista de Treinamento Sênior',
    'Analista Econômico',
    'Assistente Comercial Interno',
    'Assistente de Almoxarifado',
    'Assistente de Arquitetura',
    'Assistente Administrativo',
    'Assistente de Atendimento a Clientes',
    'Assistente de Atendimento Comercial',
    'Assistente de Cobrança',
    'Assistente de Comércio Exterior',
    'Assistente de Compras',
    'Assistente de Comunicação',
    'Assistente de Contabilidade',
    'Assistente de Contas a Pagar e Receber',
    'Assistente de Controladoria',
    'Assistente de Controle de Qualidade',
    'Assistente de Cozinha',
    'Assistente de Crédito e Cobrança',
    'Assistente de Custos',
    'Assistente de Departamento Pessoal',
    'Assistente de Desenvolvimento de Produto',
    'Assistente de Desenvolvimento de Sistemas',
    'Assistente de Distribuição',
    'Assistente de Ecommerce',
    'Assistente de Enfermagem',
    'Assistente de Engenharia Elétrica',
    'Assistente de Estoque',
    'Assistente Financeiro',
    'Assistente de Informática',
    'Assistente de Jornalismo',
    'Assistente de Limpeza',
    'Assistente de Logística',
    'Assistente de Loja',
    'Assistente de Manutenção',
    'Assistente de Manutenção Elétrica',
    'Assistente de Marketing',
    'Assistente de PCP',
    'Assistente de Produção',
    'Assistente de Publicidade e Propaganda',
    'Assistente de Qualidade',
    'Assistente de Recrutamento e Seleção',
    'Assistente de Recursos Humanos',
    'Assistente de Representação Comercial',
    'Assistente de SAC',
    'Assistente de Seguros',
    'Assistente de Sistemas',
    'Assistente de Suporte Técnico',
    'Assistente de Suprimentos',
    'Assistente de Tecnologia da Informação (TI)',
    'Assistente de Telecomunicações',
    'Assistente de Telemarketing Ativo',
    'Assistente de Telemarketing Receptivo',
    'Assistente de Tesouraria',
    'Assistente de Transportes',
    'Assistente de Treinamento',
    'Assistente de Vendas Externas',
    'Assistente Editorial',
    'Assistente Fiscal',
    'Assistente Social',
    'Astrônomo',
    'Atendente',
    'Atendente de Agência',
    'Atendente Distribuidor',
    'Auditor Ambiental',
    'Auditor de Qualidade',
    'Auditor Externo',
    'Auditor Interno',
    'Auxiliar de Almoxarifado',
    'Auxiliar de Almoxarife',
    'Auxiliar de Arquivo',
    'Auxiliar de Banco de Dados',
    'Auxiliar de Biblioteca',
    'Auxiliar de Comércio Exterior',
    'Auxiliar de Contas a Pagar e Receber',
    'Auxiliar de Controle de Qualidade',
    'Auxiliar de Cozinha',
    'Auxiliar de Crédito e Cobrança',
    'Auxiliar de Custos',
    'Auxiliar de Distribuição',
    'Auxiliar de Engenharia de Segurança do Trabalho',
    'Auviliar de Escritório',
    'Auxiliar de Fundição',
    'Auxiliar de Instalação',
    'Auxiliar de Limpeza',
    'Auxiliar de Manutenção Hidráulica',
    'Auxiliar de Padeiro',
    'Auxiliar de PCP',
    'Auxiliar de Programação',
    'Auxiliar de SAC',
    'Auxiliar de Segurança',
    'Auxiliar de Serralheiro',
    'Auxiliar de Suprimentos',
    'Auxiliar de Topografia',
    'Auxiliar de Transportes',
    'Auxiliar de Usinagem',
    'Auxiliar de Veterinária',
    'Auxiliar de Web Design',
    'Auxiliar Econômico',
    'Auxiliar Fiscal',
    'Auxiliar Jurídico',
    'Bartender',
    'Babá',
    'Balconista',
    'Bancário',
    'Barman',
    'Biblioteconista',
    'Biólogo',
    'Biomédico',
    'Bioquímico',
    'Biotecnólogo',
    'Bombeiro',
    'Borracheiro',
    'Cabeleireiro',
    'Cadista',
    'Cardiologista',
    'Cartazista',
    'Carteiro',
    'Cenógrafo',
    'Ceramista',
    'Cineasta',
    'Cinegrafista',
    'Cirurgião Plástico',
    'Clínico Geral',
    'Cobrador de Coletivo',
    'Comissário de Bordo',
    'Confeiteiro',
    'Conferente',
    'Conferente de Loja',
    'Consultor Contábil',
    'Consultor de Marketing',
    'Consultor de Moda',
    'Consultor de Qualidade',
    'Consultor de Vendas Externas',
    'Consultor de Viagens',
    'Contador',
    'Controlador de Tráfego',
    'Coordenador Administrativo',
    'Coordenador Comercial Externo',
    'Coordenador Comercial Interno',
    'Coordenador de Almoxarifado',
    'Coordenador de Arquitetura',
    'Coordenador de Atendimento Comercial',
    'Coordenador de Atendimento a Clientes',
    'Coordenador de Banco',
    'Coordenador de Cargos e Salários',
    'Coordenador de Cobrança',
    'Coordenador de Comércio Exterior',
    'Coordenador de Compras',
    'Coordenador de Comunicação',
    'Coordenador de Contabilidade',
    'Coordenador de Controladoria',
    'Coordenador de Controle de Qualidade',
    'Coordenador de Cozinha',
    'Coordenador de Crédito e Cobrança',
    'Coordenador de Custos',
    'Coordenador de Departamento Pessoal',
    'Coordenador de Desenvolvimento de Produto',
    'Coordenador de Desenvolvimento de Sistemas',
    'Coordenador de Diagramação',
    'Coordenador de Ecommerce',
    'Coordenador de Enfermagem',
    'Coordenador de Engenharia e Segurança do Trabalho',
    'Coordenador de Engenharia Elétrica',
    'Coordenador de Estoque',
    'Coordenador de Fundição',
    'Coordenador de Informática',
    'Coordenador de Jornalismo',
    'Coordenador de Limpeza',
    'Coordenador de Logística',
    'Coordenador de Loja',
    'Coordenador de Manutenção',
    'Coordenador de Manutenção Elétrica',
    'Coordenador de Marketing',
    'Coordenador de PCP',
    'Coordenador de Produção',
    'Coordenador de Publicidade e Propaganda',
    'Coordenador de Qualidade',
    'Coordenador de Recrutamento e Seleção',
    'Coordenador de Recursos Humanos',
    'Coordenador de Representação Comercial',
    'Coordenador de SAC',
    'Coordenador de Salão',
    'Coordenador de Segurança Patrimonial',
    'Coordenador de Seguros',
    'Coordenador de Sistemas',
    'Coordenador de Suporte Técnico',
    'Coordenador de Suprimentos',
    'Coordenador de Tecnologia da Informação',
    'Coordenador de Telecomunicações',
    'Coordenador de Telemarketing Ativo',
    'Coordenador de Telemarketing Receptivo',
    'Coordenador de Tesouraraia',
    'Coordenador de Transportes',
    'Coordenador de Treinamento',
    'Coordenador de Usinagem',
    'Coordenador Econômico',
    'Coordenador Financeiro',
    'Coordenador Fiscal',
    'Coordenador Químico',
    'Corretor de Seguros',
    'Cozinheiro',
    'Diretor Administrativo',
    'Dentista',
    'Desenhista',
    'Designer de Interiores',
    'Designer de Moda',
    'Diagramador',
    'Diretor Comercial Externo',
    'Diretor Comercial Interno',
    'Diretor de Cinema',
    'Diretor de Prdução',
    'Diretor de Representação Comercial',
    'Diretor de Recursos Humanos',
    'Diretor de Tecnologia da Informação (TI)',
    'Diretor de Marketing',
    'Diretor Financeiro',
    'Diretor de Informática',
    'Diretor de Jornalismo',
    'Diretor de Suporte Técnico',
    'Diretor de Logística',
    'Diretor de Departamento Pessoal',
    'Ecologista',
    'Economista',
    'Editor',
    'Educador',
    'Eletricista',
    'Eletricista de Manutenção',
    'Embalador',
    'Empreagdo (a) Doméstico (a)',
    'Encanador',
    'Encarregado de Almoxarifado',
    'Encarregado de PCP',
    'Encarregado de Recursos Humanos',
    'Encarregado de Segurança',
    'Encarregado de Transporte',
    'Encarregado de Usinagem',
    'Endocrinologista',
    'Engenheiro Têxtil',
    'Enfermeiro',
    'Engenheiro Aeronáutico',
    'Engenheiro Agrimensor',
    'Engenheiro Agrônomo',
    'Engenheiro Ambiental',
    'Engenheiro Civil',
    'Engenheiro Civil Junior',
    'Engenheiro Civil Pleno',
    'Engenheiro Civil Sênior',
    'Engenheiro da Computação',
    'Engenheiro de Alimentos',
    'Engenheiro de Engenharia e Segurança do Trabalho',
    'Engenheiro de Produção',
    'Engenheiro de Suprimentos',
    'Engenheiro de Telecomunicações',
    'Engenheiro Eletricista',
    'Engenheiro Metalúrgico',
    'Engenheiro Naval',
    'Engenheiro Nuclear',
    'Estagiário Comercial Externo',
    'Estagiário Comercial Interno',
    'Estagiário Administrativo',
    'Estagiário de Arquitetura',
    'Estagiário de Atendimento Comercial',
    'Estagiário de Atendimento a Clientes',
    'Estagiário de Banco',
    'Estagiário de Banco de Dados',
    'Estagiário de Biomedicina',
    'Estagiário de Cobrança',
    'Estagiário de Comércio Exterior',
    'Estagiário de Compras',
    'Estagiário de Comunicação',
    'Estagiário de Contabilidade',
    'Estagiário de Controladoria',
    'Estagiário de Controle de Qualidade',
    'Estagiário de Cozinha',
    'Estagiário de Custos',
    'Estagiário de Departamento Pessoal',
    'Estagiário de Desenho Industrial',
    'Estagiário de Desenvolvimento de Produto',
    'Estagiário de Desenvolvimento de Sistemas',
    'Estagiário de Diagramação',
    'Estagiário de Distribuição',
    'Estagiário de Ecommerce',
    'Estagiário de Economia',
    'Estagiário de Edificações',
    'Estagiário de Educação Física',
    'Estagiário de Enfermagem',
    'Estagiário de Engenharia de Alimentos',
    'Estagiário de Engenharia de Telecomunicações',
    'Estagiário de Engenharia e Segurança do Trabalho',
    'Estagiário de Engenharia Elétrica',
    'Estagiário de Estoque',
    'Estagiário de Gastronomia',
    'Estagiário de Gestão Abiental',
    'Estagiário de Informática',
    'Estagiário de Jornalismo',
    'Estagiário de Letras',
    'Estagiário de Logística',
    'Estagiário de Manutenção',
    'Estagiário de Manutenção Elétrica',
    'Estagiário de Marketing',
    'Estagiário de PCP',
    'Estagiário de Produção',
    'Estagiário de Publicidade e Propaganda',
    'Estagiário de Qualidade',
    'Estagiário de Recrutamento e Seleção',
    'Estagiário de Recursos Humanos',
    'Estagiário de Representação Comercial',
    'Estagiário de SAC',
    'Estagiário de Sistemas',
    'Estagiário de Suporte Técnico',
    'Estagiário de Suprimentos',
    'Estagiário de Tecnologia da Informação (TI)',
    'Estagiário de Telecomunicaçãoes',
    'Estagiário de Telemarketing Ativo',
    'Estagiário de Telemarketing Receptivo',
    'Estagiário de Tesouraria',
    'Estagiário de Veterinária',
    'Estagiário de Web Design',
    'Estagiário Financeiro',
    'Estagiário Fiscal',
    'Estagiário Químico',
    'Esteticista',
    'Estilista',
    'Estoquista',
    'Estoquista de Loja',
    'Fundidor',
    'Farmacêutico',
    'Feirante',
    'Filósofo',
    'Físico',
    'Fisioterapeuta',
    'Fonoaudiólogo',
    'Fotógrafo',
    'Fresador',
    'Fresador CNC',
    'Fresador Ferramenteiro',
    'Garçom',
    'Gari',
    'Gastrônomo',
    'Geofísico',
    'Geográfo',
    'Geólogo',
    'Gerente Administrativo',
    'Gerente Comercial Externo',
    'Gerente Comercial Interno',
    'Gerente Comercial Interno',
    'Gerente de Atendimento a Clientes',
    'Gerente de Atendimento Comercial',
    'Gerente de Banco',
    'Gerente de Cobrança',
    'Gerente de Comércio Exterior',
    'Gerente de Compras',
    'Gerente de Comunicação',
    'Gerente de Contabilidade',
    'Gerente de Contas',
    'Gerente de Controladoria',
    'Gerente de Controle de Qualidade',
    'Gerente de Cozinha',
    'Gerente de Departamento Pessoal',
    'Gerente de Desenvolvimento de Produto',
    'Gerente de Desenvolvimento de Sistemas',
    'Gerente de Distribuição',
    'Gerente de Ecommerce',
    'Gerente de Enfermagem',
    'Gerente de Engenharia Elétrica',
    'Gerente de Estoque',
    'Gerente de Hotelaria',
    'Gerente de Informática',
    'Gerente de Jornalismo',
    'Gerente de Logística',
    'Gerente de Loja',
    'Gerente de Manutenção',
    'Gerente de Manutenção Elétrica',
    'Gerente de Marketing',
    'Gerente de PCP',
    'Gerente de Produção',
    'Gerente de Publicidade e Propaganda',
    'Gerente de Qualidade',
    'Gerente de Recrutamento e Seleção',
    'Gerente de Recursos Humanos',
    'Gerente de Representação Comercial',
    'Gerente de SAC',
    'Gerente de Salão',
    'Gerente de Segurança Patrimonial',
    'Gerente de Seguros',
    'Gerente de Sistemas',
    'Gerente de Suporte Técnico',
    'Gerente de Suprimentos',
    'Gerente de Tecnologia da Informação (TI)',
    'Gerente de Telecomunicações',
    'Gerente de Telemarketing Ativo',
    'Gerente de Telemarketing Receptivo',
    'Gerente de Tesouraria',
    'Gerente de Transportes',
    'Gerente Financeiro',
    'Gerente Fiscal',
    'Gerente Químico',
    'Geriatra',
    'Gestor Ambiental',
    'Ginecologista',
    'Governanta',
    'Homeopata',
    'Historiador',
    'Inspetor de Controle de Qualidade',
    'Instalador de Acessórios Automotivos',
    'Instalador de Alarmes',
    'Instalador de Cabeamento Estruturado',
    'Instalador de Telecomunicações',
    'Instrutor de Treinamento',
    'Jornalista',
    'Jardineiro',
    'Líder de Produção',
    'Líder de Usinagem',
    'Locutor',
    'Maestro',
    'Mandrilhador',
    'Manicure/Pedicure',
    'Manobrista',
    'Maquiador',
    'Marceneiro',
    'Marinheiro',
    'Matemático',
    'Mecânico',
    'Mecânico de Automóveis',
    'Mecânico de Manutenção Hidráulica',
    'Médico',
    'MestredeObras',
    'Modelo',
    'Monitor de Atendimento a Clientes',
    'Monitor de Telemarketing Ativo',
    'Monitor de Telemarketing Receptivo',
    'Motoboy',
    'Motorista',
    'Neurologista',
    'Nutricionista',
    'Obstetra',
    'Oftalmologista',
    'Operador de Atendimento a Clientes',
    'Operador de Caixa',
    'Operador de Fundição',
    'Operador de Guilhotina',
    'Operador de Logística',
    'Operador de Produção',
    'Operador de SAC',
    'Operador de Telemarketing Ativo',
    'Operador de Telemarketing Receptivo',
    'Operador de Torno',
    'Operador de Usinagem',
    'Ortopedista',
    'Otorrinolaringologista',
    'Psicólogo',
    'Padeiro',
    'Pescador',
    'Paisagista',
    'Pediatra',
    'Pedreiro',
    'Pintor',
    'Podólogo',
    'Polidor',
    'Produtor Editorial',
    'Professor de Educação',
    'Professor de Letras',
    'Programador de Centro de Usinagem',
    'Programador de PCP',
    'Promotor Bancário',
    'Químico',
    'Recepcionista',
    'Radialista',
    'Radiologista',
    'Redator',
    'Repórter',
    'Representante Comercial',
    'Supervisor Administrativo',
    'Secretária',
    'Serralheiro',
    'Supervisor Comercial Externo',
    'Supervisor Comercial Interno',
    'Supervisor de Almoxarifado',
    'Supervisor de Atendimento a Clientes',
    'Supervisor de Atendimento Comercial',
    'Supervisor de Banco',
    'Supervisor de Cargos e Salários',
    'Supervisor de Cobrança',
    'Supervisor de Comércio Exterior',
    'Supervisor de Compras',
    'Supervisor de Comunicação',
    'Supervisor de Contabilidade',
    'Supervisor de Controladoria',
    'Supervisor de Controle de Qualidade',
    'Supervisor de Cozinha',
    'Supervisor de Crédito e Cobrança',
    'Supervisor de Custos',
    'Supervisor de Departamento Pessoal',
    'Supervisor de Desenvolvimento de Produto',
    'Supervisor de Desenvolvimento de Sistemas',
    'Supervisor de Distribuição',
    'Supervisor de Ecommerce',
    'Supervisor de Enfermagem',
    'Supervisor de Engenharia e Segurança do Trabalho',
    'Supervisor de Engenharia Elétrica',
    'Supervisor de Estoque',
    'Supervisor de Fundição',
    'Supervisor de Informática',
    'Supervisor de Limpeza',
    'Supervisor de Logística',
    'Supervisor de loja',
    'Supervisor de Manutenção',
    'Supervisor de Marketing',
    'Supervisor de Manutenção Elétrica',
    'Supervisor de PCP',
    'Supervisor de Produção',
    'Supervisor de Publicidade e Propaganda',
    'Supervisor de Qualidade',
    'Supervisor de Recrutamento e Seleção',
    'Supervisor de Recursos Humanos',
    'Supervisor de Representação Comercial',
    'Supervisor de SAC',
    'Supervisor de Salão',
    'Supervisor de Segurança',
    'Supervisor de Segurança Patrimonial',
    'Supervisor de Seguros',
    'Supervisor de Sistemas',
    'Supervisor de Suporte Técnico',
    'Supervisor de Suprimentos',
    'Supervisor de Tecnologia da Informação (TI)',
    'Supervisor de Telecomunicações',
    'Supervisor de Telemarketing Ativo',
    'Supervisor de Telemarketing Receptivo',
    'Supervisor de Tesouraria',
    'Supervisor de Transportes',
    'Supervisor de Usinagem',
    'Supervisor Econômico',
    'Supervisor Financeiro',
    'Supervisor Fiscal',
    'Técnico Ambiental',
    'Técnico de Controle de Qualidade',
    'Técnico de Edificações',
    'Técnico de Suporte',
    'Técnico em Manutenção Elétrica',
    'Técnico em Análise Clínica',
    'Técnico em Análise Química',
    'Técnico em Automação',
    'Técnico em Guia de Turismo',
    'Técnico em Hardware',
    'Técnico em Metalúrgica',
    'Técnico em Redes',
    'Técnico em Secretariado',
    'Técnico em Secretariado Escolar',
    'Técnico em Transações Imobiliárias',
    'Técnico em Zootecnia',
    'Técnico Instalador',
    'Técnico em Telecomunicações',
    'Tesoureiro',
    'Topógrafo',
    'Tradutor',
    'Traumatologista',
    'Turismólogo',
    'Urologista',
    'Vendedor Externo',
    'Vendedor de Loja',
    'Vendedor Interno',
    'Veterinário',
    'Vigilante',
    'Vigilante Condutor',
    'Vigilante de Escolta',
    'Vigilante de Monitoramento',
    'Vigilante de Segurança Patrimonial',
    'Vigilante de Segurança Social Privada',
    'Vigilante Líder',
    'Vitrinista',
    'Web Design',
    'Web Design Sênior',
    'Web Design Junior',
    'Zelador',
    'Zootecnista',
];
