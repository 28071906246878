import { inputGroups } from '@/config/form/inputGroups';
import { getDecryptedCookie, setEncryptedCookie } from '@/common/cookies';
import { Form } from '../Form';
import { Lead } from '../Lead';
/** Responsible for working with the Progressive Profile construction */
export class ProgressiveProfile extends Form {
    init() {
        throw new Error('Method not implemented.');
    }
    #cookieName = 'eqi-pp';
    constructor(props) {
        super(props?.formId, props?.styles);
        if (props?.cookieName) {
            this.#cookieName = props.cookieName;
        }
        const hasCookies = this.#checkCookies();
        if (!hasCookies)
            this.#initCookies();
    }
    /** Check if the basics cookies exists for the PP */
    #checkCookies() {
        return Boolean(getDecryptedCookie(this.#cookieName));
    }
    /** Get the cookie name */
    get ppCookieName() {
        return this.#cookieName;
    }
    /** Start the ProgressiveProfile cookie */
    #initCookies() {
        this.setCookies('default');
    }
    /** Set cookies, if they are not still set
     * @param cookieValue
     */
    setCookies(cookieValue) {
        setEncryptedCookie({
            key: this.#cookieName,
            value: JSON.stringify(cookieValue),
        });
    }
    /** Render the form, according to the cookie */
    render() {
        const cookieValue = getDecryptedCookie(this.ppCookieName);
        const cookieValueConvert = JSON.parse(cookieValue);
        this.renderForm(cookieValueConvert);
        const leadProps = Lead.getLeadFromCookie();
        if (leadProps)
            this.autoFill(leadProps);
        this.listenerSubmitForm();
    }
    /** Change the form fields based on the cookie id settings */
    changeFormId(formRendered) {
        const inputGroupsKeys = Object.keys(inputGroups);
        const currentCookie = inputGroupsKeys.indexOf(formRendered);
        const isLastGroup = inputGroupsKeys.length - 1;
        if (currentCookie === isLastGroup) {
            this.setCookies(inputGroupsKeys[0]);
        }
        else {
            const nextPosition = (currentCookie + 1) % inputGroupsKeys.length;
            this.setCookies(inputGroupsKeys[nextPosition]);
        }
    }
    /** Fill in the fields (name, email and phone) with the lead data
     * @param {Object} lead
     */
    autoFill(lead) {
        this.renderAutofillInputs(lead);
    }
}
