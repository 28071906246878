import { encrypt } from '@/common/encryption';
export class Event {
    data;
    constructor(eventType) {
        this.data = {
            id: this.#createId(eventType),
            timestamp: Date.now(),
            type: eventType,
        };
    }
    #createId(eventType) {
        const timestamp = Date.now();
        const encryptedId = encrypt(`${timestamp}-eqi-${eventType}`);
        return encryptedId;
    }
}
