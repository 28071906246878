export const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
        campaignId: params.get('campaignId') ?? '',
        utm_source: params.get('utm_source') ?? '',
        utm_content: params.get('utm_content') ?? '',
        utm_campaign: params.get('utm_campaign') ?? '',
        utm_medium: params.get('utm_medium') ?? '',
        gclid: params.get('gclid') ?? '',
    };
};
