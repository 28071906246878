import { apiConfig } from '@/config/validators';
export const validatorPhoneEQI = async (phone) => {
    try {
        const response = await fetch(apiConfig.webhookURL + phone);
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }
        const json = await response.json();
        return json.isPhoneValid;
    }
    catch (error) {
        const typedError = error;
        console.error('There has been a problem with your fetch operation: ' +
            typedError.message);
    }
};
