import { getDeviceType } from '@/common/utils/getDeviceType';
import { getQueryParams } from '@/common/utils/getQueryParams';
import { Event } from '../';
export class PageView extends Event {
    constructor(lead) {
        super('pageView');
        this.data.pageInfo = this.#getPageInfo();
        this.data.context = this.#getContext();
        this.data.lead = lead.leadDataIfValid;
    }
    #getPageInfo() {
        const origin = document.location.origin;
        const path = document.location.pathname;
        const title = document.title;
        const params = getQueryParams();
        return { origin, params, path, title };
    }
    #getContext() {
        const device = getDeviceType();
        return { device };
    }
}
