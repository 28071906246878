import { selectOptionsProfessions } from '@/config/form/professions';
// Transformar esse array de objetos em um objeto literal
export const inputGroups = {
    default: {
        name: 'Default',
        inputs: [
            {
                name: 'Name',
                label: 'Nome',
                type: 'text',
                isRequired: true,
                placeholder: 'Escreva seu Nome...',
            },
            {
                name: 'Email',
                label: 'Email',
                type: 'email',
                isRequired: true,
                placeholder: 'Digite Seu Melhor Email...',
            },
            {
                name: 'MobilePhone',
                label: 'Telefone',
                type: 'text',
                isRequired: true,
                placeholder: 'Insira Seu WhatsApp...',
            },
            {
                name: 'RDValueRangeReported__c',
                label: 'Qual valor disponível para investir hoje?',
                type: 'select',
                options: [
                    'Até R$ 30 mil',
                    'De R$ 30 mil a R$ 60 mil',
                    'De R$ 60 mil a R$ 100 mil',
                    'De R$ 100 mil a R$ 300 mil',
                    'De R$ 300 mil a R$ 500 mil',
                    'De R$ 500 mil a R$ 1 milhão',
                    'De R$ 1 milhão a R$ 5 milhões',
                    'Acima de R$ 5 milhões',
                    'Não tenho capital para investir',
                ],
                isRequired: true,
            },
            {
                name: 'State',
                label: 'Estado',
                type: 'hidden',
            },
        ],
    },
    'form-id-01': {
        name: 'Form 2',
        inputs: [
            {
                name: 'Name',
                label: 'Nome',
                type: 'text',
                isRequired: true,
                placeholder: 'Escreva seu Nome...',
            },
            {
                name: 'Email',
                label: 'Email',
                type: 'email',
                isRequired: true,
                placeholder: 'Digite Seu Melhor Email...',
            },
            {
                name: 'MobilePhone',
                label: 'Telefone',
                type: 'text',
                isRequired: true,
                placeholder: 'Insira Seu WhatsApp...',
            },
            {
                name: 'Schooling__c',
                label: 'Nível de escolaridade',
                type: 'select',
                options: [
                    'Fundamental Incompleto',
                    'Fundamental Completo',
                    'Ensino Médio',
                    'Ensino Superior',
                    'Pós-graduação',
                    'Mestrado',
                    'Doutorado',
                ],
                isRequired: true,
            },
            {
                name: 'AgeRange__c',
                label: 'Faixa etária',
                type: 'select',
                options: [
                    'De 18 a 25 anos',
                    'De 26 a 35 anos',
                    'De 36 a 45 anos',
                    'De 46 a 55 anos',
                    'De 56 a 65 anos',
                    'Acima de 66 anos',
                ],
                isRequired: true,
            },
        ],
    },
    'form-id-02': {
        name: 'Form 3',
        inputs: [
            {
                name: 'Name',
                label: 'Nome',
                type: 'text',
                isRequired: true,
                placeholder: 'Escreva seu Nome...',
            },
            {
                name: 'Email',
                label: 'Email',
                type: 'email',
                isRequired: true,
                placeholder: 'Digite Seu Melhor Email...',
            },
            {
                name: 'MobilePhone',
                label: 'Telefone',
                type: 'text',
                isRequired: true,
                placeholder: 'Insira Seu WhatsApp...',
            },
            {
                name: 'RecurrentRevenue__c',
                label: 'Média de renda mensal',
                type: 'select',
                options: [
                    'Até R$ 3 mil',
                    'Entre R$ 3 mil e R$ 10 mil',
                    'Entre R$ 10 mil e R$ 20 mil',
                    'De R$ 20 mil a R$ 50 mil',
                    'Acima de R$ 50 mil',
                ],
                isRequired: true,
            },
            {
                name: 'Job__c',
                label: 'Profissão',
                type: 'select',
                options: selectOptionsProfessions,
                isRequired: true,
            },
            {
                name: 'InvestmentKnowledge__c',
                label: 'Como você classifica o seu nível de conhecimento sobre investimentos?',
                type: 'select',
                options: [
                    { label: 'Não sei nada, preciso aprender', value: 'Leigo' },
                    {
                        label: 'Básico, estou dando meus primeiros passos',
                        value: 'Iniciante',
                    },
                    {
                        label: 'Intermediário, já tenho uma boa noção',
                        value: 'Intermediário',
                    },
                    {
                        label: 'Avançado, sei o que fazer e quando fazer',
                        value: 'Avançado',
                    },
                    {
                        label: 'Profissional, investimentos são a minha profissão',
                        value: 'Profissional',
                    },
                ],
                isRequired: true,
            },
        ],
    },
    'form-id-03': {
        name: 'Form 4',
        inputs: [
            {
                name: 'Name',
                label: 'Nome',
                type: 'text',
                isRequired: true,
                placeholder: 'Escreva seu Nome...',
            },
            {
                name: 'Email',
                label: 'Email',
                type: 'email',
                isRequired: true,
                placeholder: 'Digite Seu Melhor Email...',
            },
            {
                name: 'MobilePhone',
                label: 'Telefone',
                type: 'text',
                isRequired: true,
                placeholder: 'Insira Seu WhatsApp...',
            },
            {
                name: 'TraderKnowledge__c',
                label: 'Com qual tipo de operação você tem mais familiaridade?',
                type: 'select',
                options: [
                    'Day Trade (comprar e vender no mesmo dia)',
                    'Swing Trade (comprar num dia e vender algum tempo depois)',
                    'Buy and Hold (comprar e não vender)',
                    'Não tenho familiaridade com nenhuma',
                ],
                isRequired: true,
            },
            {
                name: 'OwnProperty__c',
                label: 'Você possui imóvel próprio atualmente?',
                type: 'select',
                options: ['Sim', 'Não'],
                isRequired: true,
            },
            {
                name: 'RiskReturn__c',
                label: 'Como você classificaria sua disposição a RISCO?',
                type: 'select',
                options: [
                    {
                        label: 'Conservador: prefiro investir correndo o mínimo de risco possível',
                        value: 'Baixo',
                    },
                    {
                        label: 'Moderado: suporto correr algum tipo de risco, desde que eu tenha uma maior previsibilidade de retorno',
                        value: 'Médio',
                    },
                    {
                        label: 'Agressivo: suporto correr maiores riscos para tentar obter ganhos maiores',
                        value: 'Alto',
                    },
                    { label: 'Avançado, sei o que fazer e quando fazer', value: 'Alto' },
                ],
                isRequired: true,
            },
            {
                name: 'HowlongInvest__c',
                label: 'Há quanto tempo você investe?',
                type: 'select',
                options: [
                    'Até 1 ano',
                    'Entre 1 e 5 anos',
                    'Entre 6 e 10 anos',
                    'Acima de 10 anos',
                    'Ainda não invisto',
                ],
                isRequired: true,
            },
        ],
    },
};
