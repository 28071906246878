import { mountOptionInput } from './input';
import { mountOptionLabel } from './label';
export const mountOption = (props, option, index) => {
    const span = document.createElement('span');
    const optionInput = mountOptionInput(props, option, index);
    const optionLabel = mountOptionLabel(props, option);
    span.className = 'option';
    span.appendChild(optionInput);
    span.appendChild(optionLabel);
    return span;
};
