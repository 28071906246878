import { mountDivClass } from '../../utils/mountDivClass';
import { mountLabel } from '../../utils/mountLabel';
import { mountTextualInput } from '../../utils/mountTextualInput';
export const renderTextualInputs = (props, styles) => {
    const label = mountLabel(props, styles);
    const inputElement = mountTextualInput(props, styles);
    const div = document.createElement('div');
    div.className = mountDivClass(props, styles);
    if (props.type === 'hidden') {
        label.hidden = true;
    }
    div.append(label);
    div.appendChild(inputElement);
    return div;
};
