export const validateEmail = (email = '') => {
    const normalizedEmail = email
        .normalize('NFD')
        .replace(/[\u0300-\u036f/\s,]/g, '')
        .toLowerCase();
    return EMAIL_REGEX.test(normalizedEmail);
};
export const validatePhone = (phone = '') => {
    const cleanPhone = phone.replace(/\D/g, '');
    return cleanPhone.length >= 10;
};
const EMAIL_REGEX = /^[^.][\w.%+-]+[^.]@[\w.-]+\.[a-z]{2,}$/i;
