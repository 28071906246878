import Cookies from 'js-cookie';
import { decrypt, encrypt } from '@/common/encryption';
import { parseJSON } from '@/common/formaters';
export const getJSONCookie = (key) => {
    const cookieValue = getDecryptedCookie(key);
    return parseJSON(cookieValue);
};
export const getDecryptedCookie = (key) => {
    const cookieValue = Cookies.get(key);
    return decrypt(cookieValue);
};
export const setEncryptedCookie = ({ key, value, options }) => Cookies.set(key, encrypt(value), {
    ...options,
    expires: 1000 * 60 * 60 * 24 * 400,
});
