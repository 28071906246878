export const mountSelectOptions = (options, select) => {
    const nullOption = mountNullOption();
    select.appendChild(nullOption);
    options.forEach((option) => {
        const optionElement = document.createElement('option');
        if (typeof option === 'object') {
            optionElement.label = option.label;
            optionElement.value = option.value;
            optionElement.append(option.value);
        }
        else {
            optionElement.value = option;
            optionElement.append(option);
        }
        select.appendChild(optionElement);
    });
};
const mountNullOption = () => {
    const nullOption = document.createElement('option');
    nullOption.value = '';
    nullOption.append('Escolha uma opção');
    return nullOption;
};
