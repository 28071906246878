export const parseJSON = (value) => {
    try {
        const json = JSON.parse(value ?? '');
        if (typeof json !== 'object') {
            throw new Error();
        }
        return json;
    }
    catch (e) {
        return null;
    }
};
