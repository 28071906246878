import { apiConfig } from '@/config/ProgressiveProfile';
export const sendEventToWebhookPP = async (inputsData) => {
    if (!apiConfig.webhookURL) {
        throw new Error('PUBLIC_WEBHOOK_SALES_INTEGRATION_URL não está definida');
    }
    fetch(apiConfig.webhookURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputsData),
    });
};
