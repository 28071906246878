import { LISTENERS_REGISTER } from './register';
/**
 * Responsável gerenciar a escuta dos eventos
 */
export class Listeners {
    tracking;
    constructor(tracking) {
        this.tracking = tracking;
    }
    /**
     * Adiciona od eventos registrados no objeto `LISTENERS_REGISTER` para serem escutados
     */
    startListening() {
        Object.entries(LISTENERS_REGISTER).forEach(([event, handler]) => document.addEventListener(event, handler({ tracking: this.tracking })));
    }
}
