export const mountOptionInput = ({ name, type, ...props }, option, index) => {
    const inputElement = document.createElement('input');
    inputElement.type = type;
    inputElement.value = option;
    inputElement.id = `form-field-${name}`;
    inputElement.name = `form_fields[${name}]`;
    if (index === 0) {
        inputElement.checked = true;
    }
    if (props.isRequired) {
        inputElement.required = props.isRequired;
        inputElement.ariaRequired = String(props.isRequired);
    }
    return inputElement;
};
