import { Submit } from './modules/Event/Submit';
import { Listeners } from './modules/Listeners';
import { ProgressiveProfile } from './modules/ProgressiveProfile';
import { Lead } from '@/modules/Lead';
import { Tracking } from '@/modules/Tracking';
const lead = new Lead(Lead.getLeadFromCookie());
let LeadTracking = {};
if (!lead.leadDataIfValid) {
    new Submit();
}
else {
    const tracking = new Tracking(lead);
    const listeners = new Listeners(tracking);
    listeners.startListening();
    LeadTracking = {
        lead,
        tracking,
        listeners,
    };
}
export { LeadTracking, ProgressiveProfile };
