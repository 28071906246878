import { sendEventToWebhook } from '@/services/http/webhook';
/**
 * Responsável por gerenciar e os dados do lead e dos eventos gerados pelo mesmo.
 */
export class Tracking {
    #lead;
    #events = [];
    constructor(lead) {
        this.#lead = lead;
    }
    /**
     * Registra a ocorrência de um novo evento e faz o envio para o webhook
     */
    addNewEvent(event) {
        this.#events.push(event);
        this.#submitEvent(event);
    }
    /**
     * Faz o envio do evento para o webhook
     */
    async #submitEvent(event) {
        await sendEventToWebhook(event.data);
    }
    /**
     * Retorna o lead que está sendo trackeado atualmente
     */
    get lead() {
        return this.#lead;
    }
    /**
     * Retorna os eventos que já ocorreram na sessão atual
     */
    get events() {
        return this.#events;
    }
}
