import { renderDatalistInputs } from './Datalist';
import { renderRadioInputs } from './Radio';
import { renderSelectInputs } from './Select';
import { renderTextualInputs } from './Text';
const renderMethod = {
    datalist: renderDatalistInputs,
    radio: renderRadioInputs,
    select: renderSelectInputs,
    default: renderTextualInputs,
};
export const renderInput = (props, styles) => (renderMethod[props.type] || renderMethod['default'])(props, styles);
